import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@chakra-ui/core'

export default function Header (props) {
  const { children } = props
  return (
    <Heading mb={2} mt={5} as="h5" size="md" position="relative" >{children}</Heading>
  )
}

Header.propTypes = {
  children: PropTypes.node
}
