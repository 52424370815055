import React, { useState } from 'react'
import PropTypes from 'prop-types'
import fetch from 'isomorphic-fetch'
import Link from 'elements/Link/Link'
import Header from 'elements/Typography/Header'
import Title from 'elements/Typography/Title'
import Layout from 'elements/Frame/Layout'
import ContactForm from './ContactForm'
import {
  Divider,
  Flex,
  Box,
  Icon,
  Text
} from '@chakra-ui/core'
import { CONTACT_API_ROUTE, FORUM_ROUTE } from 'config/routes'

export default function HelpPage ({ location }) {
  const [isSent, setIsSent] = useState(false)

  const onSubmit = async values => {
    try {
      await fetch(CONTACT_API_ROUTE, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      })
    } catch (e) {
      console.log(e)
    }
    setIsSent(true)
  }

  return (
    <Layout>

      <Title label="Support">Getting help</Title>

      <Flex align="center">
        <Icon name="users" size="50px" color="gray.500" mr={10}/>
        <Box flex="1"><Header>Community chat</Header>
          <Text>We have a <Link external styled to={FORUM_ROUTE}>community chat</Link> where our devs hang out. If you have a tech question this is probably the best place to get help. </Text>
        </Box>
      </Flex>
      <Divider mt={6}/>
      <Flex align="center">
        <Icon name="email" size="50px" color="gray.500" mr={10}/>
        <Box flex="1"> <Header>Email support</Header>

          <Text>If you prefer email just reach out to <Link styled to="mailto&#58;&#104;&#101;lp&#64;&#108;&#111;tusen&#103;in&#101;&#46;com">&#104;&#101;lp&#64;&#108;&#111;tusen&#103;in&#101;&#46;com</Link> and we will do our best to assist you.</Text>
        </Box>
      </Flex>

      <Divider mt={6}/>
      <Flex align="center">
        <Icon name="chat" size="50px" color="gray.500" mr={10}/>
        <Box flex="1"> <Header>Live chat</Header>
          <Text>You can also try us on live chat if we are online.</Text>
        </Box>
      </Flex>

      {/*
      { isSent
        ? <Text>Thanks for reaching out. We&apos;ll get back to you asap!</Text>
        : <ContactForm location={location} onSubmit={onSubmit}/>
      } */}

    </Layout>
  )
}

HelpPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  })
}
